// /* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* styles.css o styles.scss */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}


body {
  color: var(--ui-accent);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}

::view-transition-old(root) {
  animation: 70ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  animation: 170ms cubic-bezier(0, 0, 0.2, 1) 70ms both fade-in,
    220ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}



input {
  -webkit-appearance: none !important;
  appearance: none;
  @apply px-2;
}

select {
  -webkit-appearance: none !important;
  appearance: none;
}

textarea {
  -webkit-appearance: none !important;
  appearance: none;
}

a {
  -webkit-appearance: none !important;
  appearance: none;
  color: var(--ui-spaceblack);
}

body {
  position: relative !important;
  overflow: scroll !important;
}

/* Aplica el CSS solo en pantallas con un ancho mínimo de 768px */
@media only screen and (max-device-width: 812px) and (min-device-width: 375px) and (orientation: portrait) {
  body {
    margin-top: constant(safe-area-inset-top) !important;
    margin-top: env(safe-area-inset-top) !important;
  }
}

@media only screen and (max-device-width: 896px) and (min-device-width: 414px) and (orientation: portrait) {
  body {
    margin-top: constant(safe-area-inset-top) !important;
    margin-top: env(safe-area-inset-top) !important;
  }
}


// div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
//   border: 0 !important;
//   border-radius: 0.25em !important;
//   background: initial !important;
//   background-color: var(--ui-primary) !important;
//   color: #fff !important;
//   font-size: 1em !important;
// }


.swal2-title {
  @apply text-xl;
}


div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  @apply text-lg;
}

.backdrop-no-scroll {
  overflow: hidden !important;
}


/* Desactivar elastic scroll en todos los elementos de desplazamiento */
* {
  -webkit-overflow-scrolling: auto !important;
  overflow-scrolling: auto !important;
}

body,
html {
  overscroll-behavior-y: contain !important;
}



// ion-app {
//   margin-bottom: env(safe-area-inset-bottom) !important;
//   /* Status bar height on iOS 11+ */
//   margin-bottom: env(safe-area-inset-bottom) !important;
// }
// html.plt-mobile ion-app{

// }

ion-header ion-toolbar:first-of-type {
  padding-top: 0 !important;
}

.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #515151;
  height: 3rem;
  @apply text-xl;
}


.pac-item-query {
  @apply text-xl;
}

.hdpi.pac-logo:after {
  background-image: url(https://ui-cdn.digitalocean.com/aurora/assets/images/cloud-logo--white-ddc0eb211b605dc641fef57ca3526f77.svg) !important;
}

ion-app {
  margin-bottom: env(safe-area-inset-bottom);
}

ion-modal {
  margin-bottom: env(safe-area-inset-bottom);
}


.gmnoprint {
  display: none !important;

}

.gmnoprint::after {
  content: '';
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

[type=text]:focus,
input:where(:not([type])):focus,
[type=email]:focus,
[type=url]:focus,
[type=password]:focus,
[type=number]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=week]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: var(--ui-primary);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: var(--ui-primary);
  border-top-color: var(--var-primary);
  border-right-color: var(--var-primary);
  border-bottom-color: var(--var-primary);
  border-left-color: var(--var-primary);
  @apply border-primary
}





ion-infinite-scroll-content {
  --color: var(--ui-primary);
}

.blur-text::before {
  content: attr(data-text);
  color: black;
  filter: blur(3px);
  text-align: center;
  transition: filter 0.3s ease;
}

.blur-text:hover::before {
  // filter: blur(0);
  /* Elimina el desenfoque al pasar el mouse */
}